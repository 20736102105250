import Link from 'next/link'
import { signIn, signOut } from 'next-auth/react'

import { Chip } from '@/components/nopk-ui-kit/chips'
import { GridColumn, GridContainer } from '@/components/nopk-ui-kit/grid'
import { NPTLogo } from '@/components/nopk-ui-kit/logos'
import { ColumnWidthEnum, HeaderProps } from '@/interfaces/nopk-ui-kit/index'

export const Header = ({
  isLoading,
  userEmail,
  navItems,
  name,
}: HeaderProps) => (
  <header>
    <GridContainer customStyles="bg-white pt-6 mb-[78px] items-center">
      <GridColumn columnWidth={ColumnWidthEnum.SIDEBAR}>
        <Link href="/">
          <NPTLogo customStyles="w-[86px]" />
        </Link>
      </GridColumn>
      <GridColumn columnWidth={ColumnWidthEnum.CONTENT_WIDTH_END}>
        <ul
          className={`${
            isLoading
              ? 'hidden'
              : 'flex items-center space-x-4 place-content-end'
          }`}
        >
          {navItems.map((item, i) => (
            <li key={`NavItem_${i}`} className="pt-1 text-green">
              <Link href={item.href} target="_blank">
                {item.link}
              </Link>
            </li>
          ))}

          <li>
            {userEmail ? (
              <span id="authSignOut">
                <Chip
                  isActive
                  isGreeting
                  text={name?.split(' ')[0] ?? 'Sign Out'}
                  onClickCallback={() => {
                    signOut()
                  }}
                />
              </span>
            ) : (
              <span id="authSignIn">
                <div
                  aria-hidden="true"
                  className="font-bold underline text-green-medium"
                  onClick={(e) => {
                    e.preventDefault()
                    signIn('cognito')
                  }}
                >
                  Sign In
                </div>
              </span>
            )}
          </li>
        </ul>
      </GridColumn>
    </GridContainer>
  </header>
)
